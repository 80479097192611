@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "BloxyBin";
    src: url("../util/title.ttf");
}

.titleFont {
    font-family: "BloxyBin", sans-serif;
}

.dark {
    color-scheme: dark;
}

// Body styles
body {
    @apply bg-primaryBackground dark:bg-dark_primaryBackground;

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Disable the scroll bar
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

// Styling for main (content height stuff)
#content {
    padding-bottom: 277px;
    min-height: calc(100vh - 277px - 85px);
}

footer {
    height: 277px;
}

nav {
    height: 85px;
}

body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.scrollbarHidden {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.scrollbarHidden::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

//  Monospace font
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

// Island color background
.island {
    @apply bg-islandBackground dark:bg-dark_islandBackground border border-islandBorder dark:border-dark_border shadow-md;

    background-color: rgba(0,0,0,0.01);
}

.island-noshadow {
    @apply bg-islandBackground dark:bg-dark_islandBackground border border-islandBorder dark:border-dark_border;

    background-color: rgba(0,0,0,0.01);
}

// Primary background color
.primaryBackground {
    @apply bg-primaryBackground dark:bg-dark_primaryBackground;
}

.primary {
    @apply text-primary dark:text-dark_primary;
}

.secondary {
    @apply text-secondary dark:text-dark_secondary;
}

.secondaryBackground {
    @apply bg-secondaryBackground dark:bg-dark_secondaryBackground;
}

.secondaryHoverPrimary {
    @apply bg-secondaryBackground hover:bg-primaryBackground
    dark:bg-dark_secondaryBackground dark:hover:bg-dark_primaryBackground;
}

.border {
    @apply border-gray-200 dark:border-dark_border;
}

.borderHover {
    @apply hover:border-stone-300 hover:dark:border-stone-900;
}

.text {
    @apply text-text dark:text-dark_text;
}

.plusjakartasans {
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.dmsans {
    font-family: 'DM Sans', sans-serif;
}