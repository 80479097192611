// default button styles
%button {
    // other styles
    @apply text-center text-sm rounded-lg font-medium focus:outline-none transition-colors;

    // disabled styles
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

// standard button size
.standardButtonSizing {
    @apply py-2 px-5;
}

// large button sizing
.largeButtonSizing {
    @apply py-4 px-10 text-lg;
}

// primary styles
.primary-button {
    @extend %button, .text;

    @apply bg-primary hover:bg-primaryHover dark:bg-dark_primary dark:hover:bg-dark_primaryHover text-secondary;
}

// secondary styles
.secondary-button {
    @extend %button;

    @apply text-text bg-secondary hover:bg-secondaryHover dark:bg-transparent dark:border dark:text-secondary dark:text-opacity-60 dark:border-dark_border dark:hover:text-opacity-95 dark:hover:bg-white dark:hover:bg-opacity-5;
}

// success styles
.success-button {
    @extend %button, .text;

    @apply bg-success hover:bg-successHover;
}

// danger styles
.danger-button {
    @extend %button, .text;

    @apply bg-danger hover:bg-dangerHover;
}

// success styles
.success-button {
    @extend %button;

    @apply text-text bg-green-500 hover:bg-green-600;
}

.nav-btn {
    @apply items-center inline-flex m-1 lg:flex-none md:m-0 md:mx-1 text-stone-800 dark:text-gray-400 hover:bg-gray-100 hover:border-stone-200 dark:hover:bg-white dark:hover:bg-opacity-5 border border-transparent dark:hover:border-stone-800 focus:outline-none rounded-lg text-sm p-2.5 transition-all;
}

.nav-link-btn {
    height: 2.6rem;
    font-weight: 500;
    font-size: 15px;

    @apply transition-colors bg-indigo-800 rounded-xl p-2 px-5 text-white hover:bg-indigo-700 mx-1 roboto;
}