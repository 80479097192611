@tailwind utilities;
@tailwind components;
@import "index.scss";
@import "buttons.scss";

#editor-desktop {
    margin: 15px;
    margin-top: 10px;
    width: auto;
    height: 300px;
    font-size: 13px;
    @apply border border-stone-200 dark:border-stone-800 rounded-lg shadow-md;
}

.cryptoPage {
    a {
        @apply text-blue-600 hover:text-blue-700;
    }
}

#viewer {
    width: auto;
    font-size: 13px;
}

/* Grid */
.grid-two {
    grid-template-rows: auto;
    grid-template-columns: 70% 26%;
    grid-template-areas: "first second";
    grid-auto-flow: column dense;
    gap: 0px 15px;
}

.g-first {
    grid-area: first;
}

.g-second {
    grid-area: second;
}

/* General */
.recent-pastes li {
    padding-bottom: 5px;
}

.input-label {
    @apply text-gray-700 inline-block dark:text-gray-200 pb-2 mr-2 md:ml-0;
}

.input {
    @apply md:align-middle px-2 p-2 ml-0 text-sm
	focus:outline-none bg-gray-50 border border-stone-300 text-gray-900 rounded-lg inline-block dark:bg-dark_primaryBackground dark:border-dark_border dark:placeholder-gray-400 dark:text-white transition-colors;
}

.box-main {
    @extend .secondaryBackground;
    @apply rounded-xl shadow-md border border-gray-200 dark:border-stone-800;
}

/* Nav */
.nav-link-active {
    @apply block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white;
}

.nav-link-inactive {
    @apply block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700;
}

/* Fonts */
.monospace {
    font-family: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Monaco,
        Consolas, "Liberation Mono", "Courier New", monospace;
}

.roboto {
    font-family: Roboto, sans-serif;
}

.poppins {
    font-family: Poppins, sans-serif;
}

.manrope {
    font-family: Manrope, sans-serif;
}

.roboto-slab {
    font-family: Roboto Slab, sans-serif;
}

/* Popups */
.acc-popup-input {
    @apply focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-dark_secondary dark:border-dark_border dark:placeholder-gray-400 dark:text-white;
}

/* Fixes */
[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

/* #viewer li::marker {
	@apply
		text-stone-700 monospace text-base transition-colors
}

#viewer li:hover::marker {
	@apply
		dark:text-stone-500
}

#viewer li {
	padding-top: 1px;
	padding-bottom: 1px;
	@apply
		pl-2 border-l border-stone-200 bg-secondary hover:bg-stone-100 dark:bg-base_dark dark:border-stone-800 hover:dark:bg-secondary_dark transition-colors text-base text-stone-300
}

#viewer li:last-of-type { @apply rounded-br-lg } */
.paste {
    @apply h-auto rounded-xl flex flex-col border border-transparent border-stone-800 dark:border-dark_border px-4 py-3 flex-row mb-5 md:mb-0 break-all shadow-sm;

    button {
        @apply bg-indigo-800 hover:bg-indigo-700 w-fit absolute mt-auto right-0 bottom-0 rounded-br-xl rounded-tl-xl poppins font-medium transition-colors text-white;
    }

    a {
        @apply plusjakartasans w-full text-lg dark:text-white text-black text-ellipsis hover:text-indigo-400 transition-colors cursor-pointer;
    }

    .author {
        @apply text-right;
    }

    p {
        @apply dmsans text-gray-500;
    }
}

/* Account Settings */
.acc-set-column {
    @apply flex flex-col w-full mx-auto;
}

.acc-set-button {
    @apply rounded-2xl py-2 px-3 bg-primary hover:bg-primaryHover dark:bg-dark_primary dark:hover:bg-dark_primaryHover transition-colors text-white;
}

/* Components */

// for regular text inputs
.textInput {
    @extend .text, .secondaryBackground;

    // positioning
    @apply text-sm rounded-lg w-full p-2.5 focus:outline-none border dark:border-dark_border;

    &:disabled {
        @apply cursor-not-allowed;
    }
}

// for checkbox inputs
.checkBoxInput {
    @apply disabled:hover:cursor-not-allowed appearance-none border w-4 h-4 checked:bg-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 ring-offset-1
    dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-stone-900 dark:border-stone-800 checked:dark:bg-blue-600 checked:border-none;
}

// for text area inputs
.textAreaInput {
    @extend .border, .secondaryBackground, .text;

    @apply rounded-lg border w-full resize-none px-2 p-2 ml-0 text-sm focus:outline-none transition-colors;

    &:disabled {
        @apply cursor-not-allowed;
    }
}

// User Dropdown
.userDropDown {
    @apply absolute z-10 w-44 divide-y divide-gray-100 dark:divide-stone-800 right-2 md:right-auto;
    @apply rounded-lg shadow mt-12 overflow-hidden;
    @apply bg-primaryBackground dark:bg-dark_islandBackground py-4 border-primaryBorder dark:border-dark_border;

    .entry {
        @apply cursor-pointer block py-2 px-4 text-center;

        @apply hover:bg-secondaryHover dark:hover:bg-dark_secondaryHover text-text  dark:text-dark_text dark:hover:text-white;
    }
}

// Spinner utilities
.spinner {
    animation: spin infinite 5s linear;
}

/* Dropdown Input */
.dropdown {
    @apply min-w-max
    w-full
    absolute
    secondaryBackground
    text-base
    text-left
    z-50
    py-2
    px-2
    mt-1
    m-0
    float-left
    list-none
    rounded-lg
    shadow-lg
    bg-clip-padding
    border
    dark:border-dark_border;

    li {
        button {
            @apply text-sm
            py-2
            px-2
            w-full
            font-normal
            block
            whitespace-nowrap
            bg-transparent
            text
            hover:bg-primaryBackground dark:hover:bg-dark_primaryBackground;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// For pastes on the popular page.
.thumb {
    @apply flex flex-col md:w-1/4 h-full relative;
}

.thumb img {
    aspect-ratio: 16/9;
    @apply w-full h-full rounded-xl cursor-pointer;
}

.darkDropShadow {
    -webkit-filter: drop-shadow(5px 5px 5px #1c1c1c);
    filter: drop-shadow(5px 5px 5px #1c1c1c);
}

.lightDropShadow {
    -webkit-filter: drop-shadow(2px 2px 2px #1c1c1c);
    filter: drop-shadow(2px 2px 2px #1c1c1c);
}

.thumbnailShadow {
    @apply drop-shadow-xl;
}

.thumb a {
    margin-left: 10px;
    font-size: 18px;
    @apply pt-2 poppins w-full rounded-xl text-ellipsis hover:text-indigo-400 transition-colors;
}
