/* This styling is for the paste viewer (& editor i guess) */
@import "index.scss";

// The viewer box itself
.pv-box {
    @extend .island, .border;

    // positioning & etc
    @apply rounded-b-xl rounded-tl-none rounded-tr-xl shadow-md border pb-3 px-3;

    // the stats seen above the view box
    .stats {
        @extend .island;

        // colors
        @apply p-0 border-gray-200 dark:border-stone-800 text-gray-600 dark:text-gray-400;

        // positioning & etc
        @apply rounded-tl-none w-auto h-10 border-b p-1.5 pl-3 pt-2.5 rounded-xl rounded-b-none text-sm;
    }
}

// Viewer tabs
.tabs {
    display: flex;
    flex-direction: row;

    // The buttons to change tabs
    button {
        @extend .border, .borderHover, .text, .island;

        @apply transition-colors hover:bg-dark_secondaryHover pb-2 p-2 px-4 w-fit rounded-t-xl text-base hover:cursor-pointer border border-b-0 text-opacity-80;
    }
}
